<template>
  <Transition name="fade">
    <div v-if="isOpen && liaison" class="liaison-modal-wrapper" @click.self="handleClose">
      <div class="liaison-modal">
        <div class="liaison-modal__btn-close" @click="handleClose">
          <TheCrossIcon />
        </div>
        <MemberLiaisonBox class="liaison-modal-box" :liaison="liaison" />
      </div>
    </div>
  </Transition>
</template>

<script>
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'MemberLiaisonModal',
  components: {
    TheCrossIcon,
    MemberLiaisonBox: () => import(/* webpackChunkName: "MemberLiaisonBox" */ '@/components/partials/MemberLiaisonBox'),
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    liaison: {
      type: Object,
      default: () => ({
        name: '',
        email: '',
        phone: '',
        photo_url: '',
      }),
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.liaison-modal {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: rgba(black, 0.5);
  }

  position: relative;
  max-width: 700px;
  max-height: 90vh;
  margin: 0 auto;
  padding: 60px 40px 40px;
  overflow-y: auto;
  background-color: #fff;

  @media (max-width: 768px) {
    max-width: 95vw;
    padding: 45px 20px 25px;
  }
  @media (max-width: 480px) {
    padding: 45px 15px 20px;
  }

  &__btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 24px;
    height: 24px;

    @media (max-width: 768px) {
      top: 10px;
      right: 15px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
